body {
  height: 100vh;
  background-color: #2e2e2e;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex-fill {
  flex: 1 1;
}

.height-col {
  height: calc(100vh - (81px + 67.34px + 15px));
}

.height-col-favorite {
  height: calc(100vh - (81px + 67.34px + 15px));
}

#vMap {
  height: 500px;
}

.site-container {
  height: 100vh;
  overflow: auto;
}

.kllb {
  flex: 1 1 auto;
}

.text {
  color: #dc9e1f;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.right-item {
  float: right;
  padding: auto;
  margin: 1px;
}

.themes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.theme-height {
  max-height: 200px;
}

.center {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin: auto;
}

.centerl {
  display: flex;
  align-items: center;
  justify-content: center;
}

.socialWrap {
  display: flex;
  justify-content: center;
}

.link-style {
  color: none;
  text-decoration: none;
}

/*  BreadCrapmp Style     */
.breadCramLocation {
  width: 100%;
  margin: auto;
}

ul.breadcrumb {
  padding: 10px 16px;
  list-style: none;
}

ul.breadcrumb li {
  display: inline;
  font-size: 18px;
  color: #f5f5f5;
  text-decoration: none;
}

ul.breadcrumb li + li:before {
  padding: 8px;
  color: #f5f5f5;
  content: ">";
}

/*  BreadCrapmp Style     */

.color-button {
  background-color: #2e2e2e;
  max-width: 18rem;
}

.a {
  max-height: 75vh;
}

/*  AnalyisTheme Style     */

div.gallery {
  cursor: pointer;
  margin: 5px;
  border: 1px solid #ccc;
  float: left;
  width: 180px;
}

div.gallery:hover {
  border: 1px solid #777;
}

div.gallery img {
  width: 100%;
  height: 75%;
}

div.desc {
  padding: 5px;
  text-align: right;
}

/*  AnalyisTheme Style     */

.padding {
  padding: 8px 0px;
}

.image {
  display: block;
}

.loading {
  -webkit-filter: blur(10px);
          filter: blur(10px);
  -webkit-clip-path: inset(0);
          clip-path: inset(0);
}
.loaded {
  -webkit-filter: blur(0px);
          filter: blur(0px);
  transition: -webkit-filter 0.5s linear;
  transition: filter 0.5s linear;
  transition: filter 0.5s linear, -webkit-filter 0.5s linear;
}

